import {
  Button,
  Circle,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import NextImage from "next/image";
import logo from "../../../public/logo.png";
import { IoReceiptSharp } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { MdAssignmentAdd } from "react-icons/md";
import { SignOut, UserCircle, CaretDown } from "@phosphor-icons/react";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { deleteCookie, getCookie } from "cookies-next";
import Image from "next/image";
import { UserContext } from "@/userContext";
import { useQuery } from "react-query";
import { getChatUnreadMsg, getInvoiceList } from "@/api";
import { AxiosError } from "axios";
import { NotifyContext } from "@/notifyContext";
import { IInvoiceItem } from "@/lib/interfaces/IInvoiceItem";
import dynamic from "next/dynamic";
import Link from "next/link";
import { poppins } from "@/styles/font";

const Searchbar = dynamic(() => import('@/component/category-item/Searchbar'));
const Menu = dynamic(() => import('@chakra-ui/react').then(mod => mod.Menu));
const MenuButton = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuButton));
const MenuList = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuList));
const MenuDivider = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuDivider));
const MenuItem = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuItem));

const Navbar = () => {
  const router = useRouter();

  const profile = useContext(UserContext);
  const notification = useContext(NotifyContext);

  useEffect(() => {
    const cookie = getCookie("martline-user", {
      path: "/",
      sameSite: "strict"
    });

    if (cookie) {
      const json = JSON.parse(cookie.toString());
      profile.setUser(json);
    }
  }, []);

  const invoiceList
    = useQuery<IInvoiceItem[], AxiosError>("invoice-list-count",
      async () => getInvoiceList(profile.user.user), {
      useErrorBoundary: true,
      enabled: !!profile.user,
      refetchInterval: 10000,
      onSuccess(data) {
        const sentInvoices = data.filter((invoice) => (invoice.status.toLowerCase() === 'sent'));
        notification?.setNotifyInfo({
          msgCount: notification.notifyInfo?.msgCount ?? 0,
          invoiceCount: sentInvoices.length ?? 0
        });
      },
    })

  const msgCount = useQuery<any, AxiosError>('chat-msg-count',
    () => getChatUnreadMsg(
      `${profile.user.user}`,
    ), {
    useErrorBoundary: true,
    enabled: !!profile.user,
    onSuccess(data) {
      notification?.setNotifyInfo({
        msgCount: data,
        invoiceCount: notification.notifyInfo?.invoiceCount ?? 0
      });
    },
  });

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      py={"16px"}
      px={"84px"}
      boxShadow={"sm"}
      display={{ base: 'none', lg: 'flex' }}
    >
      <Link href={'/'}>
        <NextImage src={logo} alt={""} width={"100"} height={"100"} />
      </Link>

      <Searchbar />

      <Menu>
        <MenuButton
          as={Button}
          className={poppins.className}
          fontWeight={"normal"}
          backgroundColor={"transparent"}
          color={"martlinegray.900"}
          px={0}
          _hover={{
            color: "martlineorange.500",
            backgroundColor: "transparent",
            active: "transparent",
          }}
          _active={{ backgroundColor: "transparent" }}
        >
          <HStack>
            <UserCircle size={24} />
            <Text as={"span"}>Account</Text> <CaretDown style={{ display: 'inline-flex' }} />
          </HStack>
        </MenuButton>
        <MenuList
          className={poppins.className}
          fontWeight={"medium"}
          fontSize={"md"}
          color={"martlinegray.900"}
          zIndex={'dropdown'}
        >
          <MenuItem>
            {profile?.user ?
              <Stack
                direction={"row"}
                alignItems={"center"}>
                <Circle
                  size={"20px"}
                  backgroundColor={"martlinegray.400"}
                  overflow={"hidden"}>
                  {
                    profile?.user.image ? <Image src={profile?.user.image} width={100} height={100} alt={"Profile photo"} /> :
                      <UserCircle size={20} />
                  }
                </Circle>

                <Text
                  color={"martlinegray.600"}
                  className={poppins.className}
                  fontWeight={"medium"}>
                  {`Hi, ${profile?.user.phone}`}
                </Text>
              </Stack>
              :
              <Stack p={3}>
                <Text color={"martlinegray.600"}>Welcome to Martlines</Text>
                <HStack>
                  <Button
                    fontWeight={"semibold"}
                    py={2}
                    px={4}
                    borderRadius={"4px"}
                    className={poppins.className}
                    color={"white"}
                    backgroundColor={"martlineorange.500"}
                    onClick={() => router.push('/signin')}
                  >
                    Register
                  </Button>
                  <Button
                    py={2}
                    px={4}
                    backgroundColor={"white"}
                    borderWidth={"1px"}
                    borderRadius={"4px"}
                    fontWeight={"semibold"}
                    className={poppins.className}
                    borderColor={"martlineorange.500"}
                    color={"martlineorange.500"}
                    onClick={() => router.push('/signin')}
                  >
                    Sign In
                  </Button>
                </HStack>
              </Stack>
            }
          </MenuItem>
          {profile.user && <>
            <MenuDivider />
            <MenuItem
              icon={<FaUserCircle size={"1.2rem"} />}
              onClick={() => router.push("/account/profile")}
            >
              Profile
            </MenuItem>
            <MenuItem
              icon={<IoReceiptSharp size={"1.2rem"} />}
              onClick={() => router.push("/account/invoice")}
            >
              Invoice
            </MenuItem>
            <MenuItem
              icon={<BsFillChatSquareTextFill size={"1.2rem"} />}
              onClick={() => router.push("/account/messages")}
            >
              Messages
            </MenuItem>
            <MenuItem
              icon={<MdAssignmentAdd size={"1.2rem"} />}
              onClick={() => router.push("/account/saved-items")}
            >
              Saved Items
            </MenuItem>
            <MenuItem>
              {profile?.user &&
                <Button
                  backgroundColor={"error.100"}
                  color={"error.500"}
                  fontWeight={"semibold"}
                  rightIcon={<SignOut size={24} />}
                  _hover={{ bgColor: "error.500", color: "white" }}
                  onClick={() => {
                    deleteCookie("martline-user", {
                      path: "/",
                      maxAge: 32000000,
                      sameSite: "strict"
                    })
                    profile.setUser(undefined);
                    router.replace("/");
                  }}>
                  Sign out
                </Button>
              }
            </MenuItem>
          </>
          }
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Navbar;
