import { ICategory } from "./lib/interfaces/ICategory";
import { IMessage } from "./lib/interfaces/IMessage";
import { IVerify } from "./lib/interfaces/IVerify";
import axios from "axios";
import { IProfile } from "./lib/interfaces/IProfile";
import { IUser } from "./lib/interfaces/IUser";
import { ISubCategory } from "./lib/interfaces/ISubCategory";
import { IMarket } from "./lib/interfaces/IMarket";
import { IState } from "./lib/interfaces/IState";
import { IMarketDetail } from "./lib/interfaces/IMarketDetail";
import { IVendorProduct } from "./lib/interfaces/IVendorProduct";
import { IFollower } from "./lib/interfaces/IFollower";
import { IReview } from "./lib/interfaces/IReview";
import { IVendorSubCatProduct } from "./lib/interfaces/IVendorSubCatProduct";
import { ISimilarProduct } from "./lib/interfaces/ISimilarProduct";
import { InvoiceRequestType } from "./types/InvoiceRequestType";
import { IInvoiceRequest } from "./lib/interfaces/IInvoiceRequest";
import { InvoiceProductReqType } from "./types/InvoiceProductType";
import { IInvoiceRequestProduct } from "./lib/interfaces/IInvoiceRequestProduct";
import { IFavourite } from "./lib/interfaces/IFavourite";
import { FavouriteReqType } from "./types/FavouriteReqType";
import { ISavedItems } from "./lib/interfaces/ISaved";
import { IInvoice } from "./lib/interfaces/IInvoice";
import { IInvoiceItem } from "./lib/interfaces/IInvoiceItem";
import { TranReqType } from "./types/TranReqType";
import { ITransaction } from "./lib/interfaces/ITransaction";
import { Token } from "./types/SendTokenType";
import { IFeaturedMarket } from "./lib/interfaces/IFeaturedMarket";
import { ISearch } from "./lib/interfaces/ISearch";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { firestoreDb, storage } from "./pages/_app";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { firebaseConfig } from "./firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { IProduct } from "./lib/interfaces/IProduct";
import format from "date-fns/format";
import { IFollow } from "./lib/interfaces/IFollow";
import { IUnfollow } from "./lib/interfaces/IUnfollow";
import { ISingleProduct } from "./lib/interfaces/ISingleProduct";
import { IStore } from "./lib/interfaces/IStore";
import { IUserProfile } from "./lib/interfaces/IUserProfile";

firebase.initializeApp(firebaseConfig);

export const allProducts = async (page: number): Promise<IProduct> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/allproduct?page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const uploadProfileImage = async (userId: string, file: any): Promise<any> => {
  const formData = new FormData();
  formData.append('image', file);

  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/profileupdateimage/${userId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
}

export const fetchCategory = async (): Promise<ICategory[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/allcategory`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const sendOTP = async (phone: string): Promise<IMessage> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/sendotp/${phone}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const verifyOTP = async (
  serviceID: string,
  otp: string
): Promise<IVerify> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/verifyotp/${serviceID}/${otp}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const signUp = async (fullname: string, phone: string): Promise<IProfile> => {
  const tokenName = fullname.split(' ');
  let firstName, lastName;
  if (tokenName.length >= 2) {
    firstName = tokenName[0];
    lastName = tokenName[1];
  }
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/signup`,
    {
      phone: phone,
      email: "",
      firstname: firstName,
      lastname: lastName,
      image: "",
      status: "completed",
      accountType: "users",
      joined: format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS'),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const login = async (phone: string): Promise<IProfile> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/userlogin`, { phone },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const getProfileDetail = async (userId: string): Promise<IUserProfile> => {
  const response = await axios(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getuserprofile/${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const checkUserExist = async (phone: string): Promise<IUser> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/checkuser/${phone}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const getSubCategory = async (
  catId: string
): Promise<ISubCategory[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getsubcategory/${catId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const getMarket = async (
  stateId: string,
  subCatId: string
): Promise<IMarket[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getMarkets/${stateId}/${subCatId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const getState = async (): Promise<IState[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getstates`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const getMarketDetail = async (
  marketId: string,
  subCatId: string
): Promise<IMarketDetail> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getsinglemarket/${marketId}/${subCatId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.data;
};

export const getSingleProduct = async (productId: string): Promise<ISingleProduct> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/singleproduct/${productId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getStore = async (storeId: string): Promise<IStore> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getsinglestore/${storeId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getVendorProduct = async (vendorId: string): Promise<IVendorProduct[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/vendorproducts/${vendorId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const follow = async (userId: string, vendorId: string, storeId: string): Promise<IFollow> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/follow`,
    {
      user: userId,
      storeuser: vendorId,
      store: storeId
    },
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const unfollow = async (followId: string): Promise<IUnfollow> => {
  const response = await axios.delete(
    `${process.env.NEXT_PUBLIC_BASE_URL}/follow/${followId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const isFollowing = async (userId: string, vendorId: string): Promise<IFollow[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/follow/${userId}/${vendorId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getFollowerCount = async (vendorId: string): Promise<IFollower[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getfollowers/${vendorId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getAllSubCategory = async (): Promise<ISubCategory[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/allsubcategory`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getVendorReview = async (vendorId: string): Promise<IReview> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getproductreview/${vendorId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getSubCatProductVendor = async (vendorId: string, subCatId: string): Promise<IVendorSubCatProduct[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getsubcatproductvendor/${vendorId}/${subCatId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const getSimilarProducts = async (subCatId: string): Promise<ISimilarProduct> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/similarproduct/${subCatId}`,
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const createInvoice = async (invoice: InvoiceRequestType): Promise<IInvoiceRequest> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/createinvoice`, { ...invoice },
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const createInvoiceProduct = async (invoiceProduct: InvoiceProductReqType): Promise<IInvoiceRequestProduct> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/createinvoiceproduct`, { ...invoiceProduct },
    {
      headers: {
        "Content-Type": "application/json",
      }
    }
  )

  return response.data;
}

export const addFavorite = async (fav: FavouriteReqType): Promise<IFavourite> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/favourite`, { ...fav },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const getFavourite = async (userId: string): Promise<ISavedItems[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/favourite/${userId}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const updateProfile = async (userId: string, fullname: string, phone: string): Promise<IProfile> => {
  const name = fullname.trim().split(' ');
  let firstname: string = '';
  let lastname: string = '';
  firstname = name[0];
  if (name.length === 2)
    lastname = name[1];
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/profileupdate/${userId}`,
    { firstname, lastname, phone },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const getInvoiceList = async (userId: String): Promise<IInvoiceItem[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/getinvoiceuser/${userId}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const getInvoiceItem = async (invoiceId: string): Promise<IInvoice[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/invoiceproducts/${invoiceId}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const createTransaction = async (trx: TranReqType): Promise<ITransaction> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/createtransaction/${trx.admin}`,
    { ...trx },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const updateInvoiceItem = async (invoiceId: string, status: string): Promise<IInvoiceItem> => {
  const response = await axios.put(
    `${process.env.NEXT_PUBLIC_BASE_URL}/invoiceproduct/${invoiceId}`,
    {
      status: status,
      updatedDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS'), //moment().format('yyyy-MM-DD HH:mm:ss.SSS'),
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const notifyVendor = async (tokenData: Token): Promise<Object> => {
  const response = await axios.post(
    `${process.env.NEXT_PUBLIC_BASE_URL}/sendtoken`,
    {
      ...tokenData
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const getFeaturedMarket = async (userId: string): Promise<IFeaturedMarket[]> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/featured-market/${userId}`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const search = async (searchTerm?: string, catId?: string): Promise<ISearch> => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_BASE_URL}/search/${searchTerm}?catID=${catId}&page=1&limit=20`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  )

  return response.data;
}

export const getChatList = async (userId: string) => {
  const chatListQuery = query(
    collection(firestoreDb,
      'UserChat', userId, 'individual'),
    orderBy('lastMessageTime', 'desc')
  );
  const chatListSnapshot = await getDocs(chatListQuery);
  return chatListSnapshot.docs.map((item) => item.data());
};

export const getChatUnreadMsg = async (userId: string) => {
  return new Promise<any>((resolve, reject) => {

    const chatListQuery = query(
      collection(firestoreDb,
        'UserChat', userId, 'individual'),
      where('read', '==', false),
      where('chatid', '!=', userId)
    );

    onSnapshot(chatListQuery, (querySnapshot) => {
      resolve(querySnapshot.size);
    }, (error) => {
      reject(error);
    });
  });
}

export const markMsgRead = async (chatId1: string, chatId2: string) => {
  const refSender = doc(firestoreDb, 'UserChat', chatId1, 'individual', chatId2);
  const refReceiver = doc(firestoreDb, 'UserChat', chatId2, 'individual', chatId1);

  await updateDoc(refSender, {
    read: true
  });

  await updateDoc(refReceiver, {
    read: true
  });
}

export const getChatMessages = async (chatId1: string,
  chatId2: string) => {
  return new Promise<any[]>((resolve, reject) => {
    const chatMsgQuery = query(
      collection(firestoreDb, 'chats', chatId1, 'messages'),
      where('chatId', 'in', [`${chatId1}-${chatId2}`, `${chatId2}-${chatId1}`]),
      orderBy('createdAt', 'asc')
    );

    const chats: any[] = [];
    const unsubscribe = onSnapshot(chatMsgQuery, (querySnapshot) => {
      chats.length = 0; // Clear the existing chats array

      querySnapshot.forEach((item) => {
        chats.push(item.data());
      });

      resolve(chats); // Resolve the Promise with the updated chats array
    }, (error) => {
      reject(error); // Reject the Promise if an error occurs
    });
  });
}

export const sendMessage = async (name: string, msg?: string, file?: any,
  chatId1: string = '',
  chatId2: string = '',
  username: string = '') => {
  const msgImgRef = ref(storage, `image/${name}`);
  let newMsg: any;

  const refMsg1 = doc(collection(firestoreDb,
    'chats', chatId1, 'messages'));
  const refMsg2 = doc(collection(firestoreDb,
    'chats', chatId2, 'messages'));

  if (file) {
    uploadBytes(msgImgRef, file).then(() => {
      getDownloadURL(msgImgRef).then(async (url) => {
        newMsg = {
          productImage: url,
          message: msg?.trim() ?? '',
          // chatId: `${chatId1}-`,
          chatId: `${chatId1}-${chatId2}`,
          idUser: chatId1,
          urlAvatar: '',
          username: username.trim(),
          createdAt: serverTimestamp(),
          read: false
        }
        await setDoc(refMsg1, newMsg);
        await setDoc(refMsg2, newMsg);
      });
    });
  } else {
    newMsg = {
      productImage: null,
      message: msg?.trim(),
      // chatId: `${chatId1}-`,
      chatId: `${chatId1}-${chatId2}`,
      idUser: chatId1,
      urlAvatar: '',
      username: username.trim(),
      createdAt: serverTimestamp(),
      read: false
    }
    await setDoc(refMsg1, newMsg);
    await setDoc(refMsg2, newMsg);
  }

  const refSender = doc(firestoreDb, 'UserChat', chatId1, 'individual', chatId2);
  const refReceiver = doc(firestoreDb, 'UserChat', chatId2, 'individual', chatId1);

  let lastMsg;
  if (msg === null || file === true) {
    lastMsg = 'A file';
  } else if (msg || file === false) {
    lastMsg = msg;
  } else {
    lastMsg = msg;
  }

  await updateDoc(refSender, {
    chatid: `${chatId1}`,
    lastMessageTime: serverTimestamp(),
    lastMessage: lastMsg,
    read: false
  });

  await updateDoc(refReceiver, {
    chatid: `${chatId1}`,
    lastMessageTime: serverTimestamp(),
    lastMessage: lastMsg,
    read: false
  });
}

export const checkChatExists = async (
  chatId1: string, chatId2: string
) => {
  const refSender = doc(collection(firestoreDb,
    'UserChat', chatId2, 'individual'), chatId1);
  const refReceiver = doc(collection(firestoreDb,
    'UserChat', chatId1, 'individual'), chatId2);

  const refSenderSnap = await getDoc(refSender);
  const refReceiverSnap = await getDoc(refReceiver);

  return refSenderSnap.exists() && refReceiverSnap.exists();
}

export const initiateChat = async (
  chatId1: string,  // current user/sender id
  chatId2: string,  // receiver user id
  senderName: string,
  receiverName: string,
  senderAvatar: string,
  receiverAvatar: string,
  senderToken: string,
  receiverToken: string,
  senderMobile: string,
  receiverMobile: string,
) => {
  const refSender = doc(collection(firestoreDb,
    'UserChat', chatId2, 'individual'), chatId1);
  const refReceiver = doc(collection(firestoreDb,
    'UserChat', chatId1, 'individual'), chatId2);

  const exists = await checkChatExists(chatId1, chatId2);
  if (exists) return;

  await setDoc(refSender, {
    chatid: chatId1,
    read: false,
    idUser: chatId1,
    name: senderName,
    token: senderToken,
    receiveruserId: chatId1,
    block: false,
    userMobile: senderMobile,
    lastMessage: '',
    urlAvatar: senderAvatar,
    lastMessageTime: serverTimestamp(),
  });

  await setDoc(refReceiver, {
    chatid: chatId1,
    read: false,
    idUser: chatId2,
    name: receiverName,
    token: receiverToken,
    receiveruserId: chatId2,
    block: false,
    userMobile: receiverMobile,
    lastMessage: '',
    urlAvatar: receiverAvatar,
    lastMessageTime: serverTimestamp()
  });
}