import {
  Flex,
  HStack,
  IconButton,
  Show,
  Stack,
  Button,
  Text,
  useDisclosure,
  VStack,
  Divider,
  Circle,
} from "@chakra-ui/react";
import { CgMenuLeft } from "react-icons/cg";
import NextImage from "next/image";
import { CaretLeft, House, SignOut, UserCircle } from "@phosphor-icons/react";

import logo from "../../../public/logo.png";
import { poppins } from "@/styles/font";
import { useRouter } from "next/router";
import { FaUserCircle } from "react-icons/fa";
import { IoReceiptSharp } from "react-icons/io5";
import { BsFillChatSquareTextFill } from "react-icons/bs";
import { MdAssignmentAdd } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useQuery, useQueryClient } from "react-query";
import { ICategory } from "@/lib/interfaces/ICategory";
import { AxiosError } from "axios";
import { fetchCategory, getAllSubCategory } from "@/api";
import { CategoryItemType } from "@/types/CategoryItemType";
import { deleteCookie, getCookie } from "cookies-next";
import { UserContext } from "@/userContext";
import { ISubCategory } from "@/lib/interfaces/ISubCategory";
import dynamic from "next/dynamic";

const Searchbar = dynamic(() => import('@/component/category-item/SearchbarSM'));
const Menu = dynamic(() => import('@chakra-ui/react').then(mod => mod.Menu));
const MenuButton = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuButton));
const MenuDivider = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuDivider));
const MenuItem = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuItem));
const MenuList = dynamic(() => import('@chakra-ui/react').then(mod => mod.MenuList));
const Drawer = dynamic(() => import('@chakra-ui/react').then(mod => mod.Drawer));
const DrawerOverlay = dynamic(() => import('@chakra-ui/react').then(mod => mod.DrawerOverlay));
const DrawerContent = dynamic(() => import('@chakra-ui/react').then(mod => mod.DrawerContent));
const DrawerCloseButton = dynamic(() => import('@chakra-ui/react').then(mod => mod.DrawerCloseButton));
const DrawerHeader = dynamic(() => import('@chakra-ui/react').then(mod => mod.DrawerHeader));
const DrawerBody = dynamic(() => import('@chakra-ui/react').then(mod => mod.DrawerBody));

export const CategoryItem = ({ catId, image, title }: CategoryItemType) => {
  return (
    <Link
      href={{
        pathname: "/catalog/[categoryId]",
        query: { categoryId: catId, category: title.toLowerCase().replaceAll(" ", "-") },
      }}
    >
      <HStack>
        <Image src={image} alt={title} width={22} height={22} />
        <Text
          fontWeight={"normal"}
          color={"white"}
          className={poppins.className}
          fontSize={"12px"}
          _hover={{ color: "martlineorange.500" }}
        >
          {title}
        </Text>
      </HStack>
    </Link>
  );
};

// Navbar for small screens
const NavbarSM = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const router = useRouter();
  const path = router.asPath;
  const queryClient = useQueryClient();

  const profile = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const cookie = getCookie("martline-user", {
      path: "/",
      sameSite: "strict"
    });

    if (cookie) {
      const json = JSON.parse(cookie.toString());
      profile.setUser(json);
    }
  }, []);

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: allCategory,
  } = useQuery<ICategory[], AxiosError>("all-categories", fetchCategory, {
    initialData: queryClient.getQueryData("all-categories"),
    useErrorBoundary: true,
  });

  const { isLoading: isLoadingSubCat,
    isSuccess: isSuccessSub,
    isError: isErrorSub,
    error: errorSubCat,
    data: dataSubCat
  }
    = useQuery<ISubCategory[], AxiosError>("allsubcategory",
      async () => getAllSubCategory(), {
      useErrorBoundary: true,
    });

  return (
    <Show breakpoint="(max-width: 768px)">
      <Flex flexDirection={"column"}>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={menuBtnRef}
          placement={"left"}
        >
          <DrawerOverlay />
          <DrawerContent
            fontFamily={poppins.className}
            backgroundColor={"martlinegray.600"}
            roundedTopRight={"3xl"}
            roundedBottomRight={"3xl"}
          >
            <DrawerCloseButton color={"white"} />
            <DrawerHeader
              fontWeight={"semibold"}
              color={"white"}
              className={poppins.className}
              mt={8}
            >
              All Categories
            </DrawerHeader>

            <DrawerBody>
              <VStack spacing={6} alignItems={"start"}>
                {allCategory?.map((item) => (
                  <CategoryItem
                    key={item._id}
                    catId={item._id}
                    image={item.icon}
                    title={item.title}
                  />
                ))}
              </VStack>

              <Divider orientation={"horizontal"} mt={10} mb={5} />
              <Button
                variant={"ghost"}
                leftIcon={<House size={24} weight={"fill"} />}
                className={poppins.className}
                fontWeight={"medium"}
                color={"white"}
                onClick={() => router.push("/")}
              >
                Home
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        {/* TOP BAR LAYOUT */}
        <Flex
          backgroundColor={"white"}
          justifyContent={"space-between"}
          alignItems={"center"}
          w={"full"}
          py={3}
          px={5}
        >
          {
            path !== "/" ? <IconButton
              ref={menuBtnRef}
              variant={"ghost"}
              icon={<CaretLeft size={28} color={"black"} />}
              aria-label={"Mobile nav menu"}
              _active={{ backgroundColor: "martlineorange.100" }}
              _focus={{ backgroundColor: "martlineorange.100" }}
              onClick={() => router.back()}
            /> : <IconButton
              ref={menuBtnRef}
              variant={"ghost"}
              icon={<CgMenuLeft size={"1.8rem"} color={"black"} />}
              aria-label={"Mobile nav menu"}
              _active={{ backgroundColor: "martlineorange.100" }}
              _focus={{ backgroundColor: "martlineorange.100" }}
              onClick={onOpen}
            />
          }

          <Link href={'/'}>
            <NextImage src={logo} alt={""} width={"100"} height={"100"} />
          </Link>

          <Menu>
            <MenuButton
              as={Button}
              className={poppins.className}
              fontWeight={"normal"}
              backgroundColor={"transparent"}
              color={"martlinegray.900"}
              px={0}
              _hover={{
                color: "martlineorange.500",
                backgroundColor: "transparent",
                active: "transparent",
              }}
              _active={{ backgroundColor: "transparent" }}
            >
              <UserCircle size={30} />
            </MenuButton>
            <MenuList
              className={poppins.className}
              fontWeight={"medium"}
              fontSize={"md"}
              color={"martlinegray.900"}
              zIndex={'dropdown'}
            >
              <MenuItem>
                {profile?.user ?
                  <Stack
                    direction={"row"}
                    alignItems={"center"}>
                    <Circle
                      size={"20px"}
                      backgroundColor={"martlinegray.400"}
                      overflow={"hidden"}>
                      {
                        profile?.user.image ? <Image src={profile?.user.image} width={100} height={100} alt={"Profile photo"} /> :
                          <UserCircle size={20} />
                      }
                    </Circle>

                    <Text
                      color={"martlinegray.600"}
                      className={poppins.className}
                      fontWeight={"medium"}>
                      {`Hi, ${profile?.user.phone}`}
                    </Text>
                  </Stack>
                  :
                  <Stack p={3}>
                    <Text color={"martlinegray.600"}>Welcome to Martlines</Text>
                    <HStack>
                      <Button
                        fontWeight={"semibold"}
                        py={2}
                        px={4}
                        borderRadius={"4px"}
                        className={poppins.className}
                        color={"white"}
                        backgroundColor={"martlineorange.500"}
                        onClick={() => router.push('/sigin')}
                      >
                        Register
                      </Button>
                      <Button
                        py={2}
                        px={4}
                        backgroundColor={"white"}
                        borderWidth={"1px"}
                        borderRadius={"4px"}
                        fontWeight={"semibold"}
                        className={poppins.className}
                        borderColor={"martlineorange.500"}
                        color={"martlineorange.500"}
                        onClick={() => router.push('/signin')}
                      >
                        Sign In
                      </Button>
                    </HStack>
                  </Stack>
                }
              </MenuItem>
              {profile.user && <>
                <MenuDivider />
                <MenuItem
                  icon={<FaUserCircle size={"1.2rem"} />}
                  onClick={() => router.push("/account/profile")}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  icon={<IoReceiptSharp size={"1.2rem"} />}
                  onClick={() => router.push("/account/invoice")}
                >
                  Invoice
                </MenuItem>
                <MenuItem
                  icon={<BsFillChatSquareTextFill size={"1.2rem"} />}
                  onClick={() => router.push("/account/messages")}
                >
                  Messages
                </MenuItem>
                <MenuItem
                  icon={<MdAssignmentAdd size={"1.2rem"} />}
                  onClick={() => router.push("/account/saved-items")}
                >
                  Saved Items
                </MenuItem>
                <MenuItem>
                  {profile?.user &&
                    <Button
                      backgroundColor={"error.100"}
                      color={"error.500"}
                      fontWeight={"semibold"}
                      rightIcon={<SignOut size={24} />}
                      _hover={{ bgColor: "error.500", color: "white" }}
                      onClick={() => {
                        deleteCookie("martline-user", {
                          path: "/",
                          maxAge: 32000000,
                          sameSite: "strict"
                        })
                        profile.setUser(undefined);
                        router.replace("/");
                      }}>
                      Sign out
                    </Button>
                  }
                </MenuItem>
              </>
              }
            </MenuList>
          </Menu>
        </Flex>

        {/* SEARCH BAR LAYOUT */}
        {router.asPath === '/' &&
          <Flex
            py={3}
            px={5}
            w={"full"}
            backgroundColor={"martlineorange.500"}
            boxShadow={"md"}
          >
            <Searchbar />
          </Flex>
        }
      </Flex>
    </Show>
  );
};

export default NavbarSM;
