import { createContext, useState } from "react";

export type NotifyInfo = {
    msgCount: any;
    invoiceCount: any;
}

type NotifyContextType = {
    notifyInfo: NotifyInfo | null;
    setNotifyInfo: React.Dispatch<React.SetStateAction<NotifyInfo | null>>;
}

type UserContextProviderProps = {
    children: React.ReactNode;
}

export const NotifyContext = createContext({} as NotifyContextType);

export const NotifyContextProvider = ({ children }: UserContextProviderProps) => {
    const [notifyInfo, setNotifyInfo] = useState<NotifyInfo | null>(null);

    return <NotifyContext.Provider value={{ notifyInfo, setNotifyInfo }}>
        {children}
    </NotifyContext.Provider>
}