import { Box, Show } from "@chakra-ui/react";
import { useRouter } from "next/router";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";
import NavbarSM from "./navbar/Navbar-sm";

type LayoutType = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutType) => {
  const router = useRouter()

  return (
    <>
      {/* Navbar */}
      {(router.route !== '/register') &&
        <Box as={"nav"}>
          <Show above="md">
            <Navbar />
          </Show>
          <Show below="md">
            <NavbarSM />
          </Show>
        </Box>
      }

      {/* Main */}
      {(router.route !== '/register') ?
        <Box as={"main"} px={{ base: 0, lg: "84px" }} backgroundColor={"#F3F4F6"} maxWidth={'1440px'} mx={'auto'}>
          {children}
        </Box>
        :
        <Box as={"main"} backgroundColor={"#F3F4F6"} maxWidth={'1440px'} mx={'auto'}>
          {children}
        </Box>
      }

      {/* Footer */}
      {(router.route !== '/register') &&
        <Box as={"footer"}>
          <Footer />
        </Box>
      }
    </>
  );
};

export default Layout;
