import { createContext, useState } from "react";

type UserContextType = {
    user: any | null;
    setUser: React.Dispatch<any>;
}

type UserContextProviderProps = {
    children: React.ReactNode;
}

export const UserContext = createContext({} as UserContextType);

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
    const [user, setUser] = useState<any|null>(null);

    return <UserContext.Provider value={{user, setUser}}>
        {children}
    </UserContext.Provider>
}