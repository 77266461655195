import Layout from "@/component/Layout";
import { NotifyContextProvider } from "@/notifyContext";
import "@/styles/globals.css";
import { theme } from "@/styles/theme";
import { UserContextProvider } from "@/userContext";
import { ChakraProvider } from "@chakra-ui/react"
import type { AppProps } from "next/app";
import { QueryClient } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from "@/firebase";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import dynamic from "next/dynamic";

const QueryClientProvider = dynamic(() => import('react-query').then((mod) => mod.QueryClientProvider));
const NextNProgress = dynamic(() => import("nextjs-progressbar"));

const queryClient = new QueryClient();
export const app = initializeApp(firebaseConfig);
export const firestoreDb = getFirestore(app);
export const storage = getStorage(app);

export default function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <UserContextProvider>
          <NotifyContextProvider>
            <Layout>
              <NextNProgress />
              <Component {...pageProps} />
            </Layout>
          </NotifyContextProvider>
        </UserContextProvider>
      </ChakraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}