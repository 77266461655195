import { extendTheme } from "@chakra-ui/react";
import { checkboxTheme } from "./checkbox";
import { radioTheme } from "./radio";

export const theme = extendTheme({
    colors: {
        martlineorange: {
            100: 'hsla(25, 100%, 95%, 1)',
            500: 'hsla(25, 100%, 54%, 1)'
        },
        martlinegray: {
            100: 'hsla(220, 22%, 96%, 1)',
            200: 'hsla(232, 22%, 36%, 1)',
            300: 'hsla(220, 13%, 91%, 1)',
            400: 'hsla(216, 12%, 84%, 1)',
            500: 'hsla(220, 13%, 46%, 1)',
            600: 'hsla(220, 9%, 46%, 1)',
            700: 'hsla(217, 23%, 27%, 1)',
            800: 'hsla(217, 19%, 27%, 1)',
            900: 'hsla(215, 28%, 17%, 1)'
        },
        success: {
            50: 'hsla(144, 83%, 96%, 1)',
            100: 'hsla(141, 82%, 90%, 1)',
            200: 'hsla(89, 87%, 83%, 1)',
            500: 'hsla(169, 98%, 33%, 1)',
            600: 'hsla(101, 63%, 48%, 1)',
            blue: {
                400: 'hsla(216, 96%, 82%, 0.75)'
            }
        },
        error: {
            100: 'hsla(19, 100%, 92%, 1)',
            500: 'hsla(359, 100%, 60%, 1)'
        }
    },
    components: {
        Button: {
            variants: {
                'solid': {
                    backgroundColor: 'martlineorange.500',
                    color: 'white',
                    fontWeight: 'semibold',
                    fontSize: 'md',
                    transition: 'all .3s',
                    _hover: {color: 'martlineorange.500'}
                },
                'secondary': {
                    backgroundColor: 'white',
                    color: 'martlineorange.500',
                    fontWeight: 'semibold',
                    fontSize: 'md'
                },
                'outline': {
                    backgroundColor: 'white',
                    borderColor: 'martlineorange.500',
                    color: 'martlineorange.500',
                    borderWidth: '1px',
                    fontWeight: 'semibold'
                },
                'ghost': {
                    backgroundColor: 'transparent',
                    color: 'martlineorange.500',
                    fontWeight: 'semibold'
                }
            }
        },
        Radio: radioTheme,
        Checkbox: checkboxTheme
    }
})