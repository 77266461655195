import { Button, Flex, HStack, Icon, Image, Text, VStack } from "@chakra-ui/react";
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillYoutube,
  AiFillFacebook,
} from "react-icons/ai";
// import Image from "next/image";
import logo from "../../../public/logo-white.svg";
import googlePlay from "../../../public/google-play.svg";
import appStore from "../../../public/app-store.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import { poppins } from "@/styles/font";
import { UserContext } from "@/userContext";
import { useContext } from "react";

const Footer = () => {
  const router = useRouter();
  const profile = useContext(UserContext);

  return (
    <Flex
      flexDirection={"column"}
      backgroundColor={"martlinegray.900"}
      py={"16px"}
      px={{ base: 5, lg: "84px" }}
    >
      <Flex justifyContent={"space-between"} w={"full"}>
        <Flex flexDirection={"column"}>
          <Flex>
            <Image src={"/logo-white.svg"} alt={""} />
            <VStack
              spacing={{ base: 2, lg: 4 }}
              alignItems={"start"}
              justifyContent={"center"}
              ml={3}
            >
              <Text
                className={poppins.className}
                fontWeight={"semibold"}
                fontSize={{ base: "12px", lg: "18px" }}
                textTransform={"uppercase"}
                color={"white"}
              >
                Download Martlines Free App
              </Text>

              <HStack justifyContent={"space-between"}>
                <Link href={''}>
                  <Image src={'/app-store.svg'} alt={"Apple store"} />
                </Link>
                <Link href={'https://play.google.com/store/apps/details?id=com.martlines'}>
                  <Image src={'/google-play.svg'} alt={"Google Play store"} />
                </Link>
              </HStack>
            </VStack>
          </Flex>

          <Flex
            flexDirection={"row"}
            mt={7}
            display={{ base: "flex", lg: "none" }}
          >
            <VStack flexGrow={2} alignItems={"start"} spacing={1}>
              <Text
                className={poppins.className}
                textTransform={"uppercase"}
                fontWeight={"semibold"}
                fontSize={"12px"}
                color={"white"}
              >
                new to martlines?
              </Text>
              <Text
                className={poppins.className}
                fontWeight={"normal"}
                fontSize={"12px"}
                color={"white"}
              >
                Buy from all market across Nigeria
              </Text>
            </VStack>
            <HStack mt={"10px"} flexGrow={1}>
              {profile.user?.user === undefined && <Button
                variant={"solid"}
                size={"sm"}
                fontSize={"12px"}
                className={poppins.className}
                onClick={() => router.push("/signin")}
              >
                Sign up
              </Button>
              }

              <Button
                variant={"secondary"}
                size={"sm"}
                fontSize={"12px"}
                className={poppins.className}
                onClick={() => router.push("/open-a-shop")}
              >
                Open a Shop
              </Button>
            </HStack>
          </Flex>

          <Link href={"/about"}>
            <Text
              textTransform={"uppercase"}
              mt={{ base: 7, lg: 14 }}
              color={"white"}
              className={poppins.className}
              fontWeight={"semibold"}
              fontSize={{ base: "12px", lg: "md" }}
              transition={"all .4s"}
              _hover={{ color: "martlineorange.500" }}
            >
              About martlines
            </Text>
          </Link>

          <Link href={"/terms-of-use"}>
            <Text
              textTransform={"uppercase"}
              mt={3}
              color={"white"}
              className={poppins.className}
              fontWeight={"semibold"}
              fontSize={{ base: "12px", lg: "md" }}
              transition={"all .4s"}
              _hover={{ color: "martlineorange.500" }}
            >
              terms of use
            </Text>
          </Link>

          <HStack mt={3} spacing={6}>
            <Text
              textTransform={"uppercase"}
              color={"white"}
              className={poppins.className}
              fontSize={{ base: "12px", lg: "md" }}
              fontWeight={"semibold"}
            >
              join us on:
            </Text>
            <HStack color={"white"} spacing={5}>
              <Link href={'https://www.facebook.com/martlines.ng'}>
                <Icon boxSize={6} as={AiFillFacebook} aria-label={"Facebook"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
              </Link>
              <Link href={'https://twitter.com/MartlinesNg'}>
                <Icon boxSize={6} as={AiOutlineTwitter} aria-label={"Twitter"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
              </Link>
              <Link href={'https://www.instagram.com/tony_martlines'}>
                <Icon boxSize={6} as={AiFillInstagram} aria-label={"Instagram"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
              </Link>
              <Link href={'https://youtube.com/@MartlinesNG'}>
                <Icon boxSize={6} as={AiFillYoutube} aria-label={"Facebook"} _hover={{ cursor: 'pointer', color: 'martlineorange.500' }} />
              </Link>
            </HStack>
          </HStack>
        </Flex>

        {/* LARGE SCREEN */}
        <Flex flexDirection={"column"} display={{ base: "none", lg: "flex" }}>
          <Text
            className={poppins.className}
            textTransform={"uppercase"}
            fontWeight={"semibold"}
            fontSize={"18px"}
            color={"white"}
          >
            new to martlines?
          </Text>
          <Text
            className={poppins.className}
            fontWeight={"normal"}
            fontSize={"md"}
            color={"white"}
          >
            Buy from all market across Nigeria
          </Text>
          <HStack mt={"10px"}>
            {profile.user?.user === undefined && <Button
              variant={"solid"}
              // size={"sm"}
              fontSize={"12px"}
              className={poppins.className}
              onClick={() => router.push("/register")}
            >
              Sign up
            </Button>
            }

            <Button
              variant={"secondary"}
              className={poppins.className}
              onClick={() => router.push("/open-a-shop")}
            >
              Open a Shop
            </Button>
          </HStack>
        </Flex>
      </Flex>

      <Text
        className={poppins.className}
        fontWeight={"semibold"}
        fontSize={"sm"}
        color={"white"}
        mt={20}
        textAlign={"center"}
      >
        Copyright 2023 ©. Martlines NG All rights reserved
      </Text>
    </Flex>
  );
};

export default Footer;
