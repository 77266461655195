import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys)

  const baseStyle = definePartsStyle({
    control: {
      padding: 2,
      borderRadius: 999,
      borderColor: 'martlineorange.500'
    },
    label: {
        width: '100%'
    }
  })

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })